:root {
  --vacancy-background: rgb(222,222,222);
}

@media screen and (max-width: 900px) {
    body {
        background-color: rgb(222,222,222);
    }
    .css-2imjyh{
        display:block !important;
        padding: 1% 5%;
    }
    .css-1dkzbbk{
        max-Height: 1000px !important;
        height:auto !important;
        overflow: auto !important;
        padding: 1% 0%;
    }
}

.css-1dkzbbk{
    max-Height: 740px !important;
    height:auto !important;
    overflow: auto !important;
}


input, select {
  font-size: 100%;
}

.application-selector {
  background-color: white;
  border-radius: 8px;
  width: calc(100% + 20px);
}

.application-selector div {
  color: #000;
}
.application-selector div div button {
  color: grey;
}

.gdpr-body {
  background: #fff;
  color: #000;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 28px;
  margin:0;
}

.gdpr-body h1, h2, h3, h4, h5, h6, li, p {
  margin:0 0 16px
}

.gdpr-body h1 {
    font-size: 40px;
    line-height:60px
}

.gdpr-body h1, h2 {
    font-weight:700
}

.gdpr-body h2 {
    font-size: 32px;
    line-height:48px
}

.gdpr-body h3 {
    font-size: 24px;
    line-height:36px
}

.gdpr-body h3, h4 {
    font-weight:700
}

.gdpr-body h4 {
    font-size: 20px;
    line-height:30px
}

.gdpr-body h5, h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight:700
}

.gdpr-body a {
    text-decoration: none;
    cursor: pointer;
    color:#000
}

.gdpr-body a:hover, a[rel ~ =nofollow] {
    text-decoration:underline
}

.gdpr-body a[rel ~ =nofollow] {
    color:#553df4
}

.gdpr-body a[rel ~ =nofollow]:hover {
    text-decoration:none
}

.gdpr-visible {
    display:block
}

.gdpr-hidden {
    display:none
}

.gdpr-page {
    width:100%
}

.gdpr-container {
    position: relative;
    width: 90%;
    max-width: 1024px;
    margin:0 auto
}

.gdpr-header {
    padding:16px 0
}

.gdpr-header .gdpr-title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    margin:0
}

.gdpr-translations-list-container {
    padding-bottom: 8px;
    margin:0 0 16px
}

.gdpr-translations-list-container .gdpr-translations-list {
    margin: 0;
    padding: 0;
    list-style:none
}

.gdpr-translations-list-container .gdpr-translations-list .gdpr-translations-list-item {
    display: inline-block;
    padding: 0;
    margin: 0 8px 8px 0;
    font-weight: 700;
    color:#553df4
}

.gdpr-translations-list-container .gdpr-translations-list .gdpr-translations-list-item a {
    display: inline-block;
    color: #553df4;
    border: 1px solid #553df4;
    border-radius: 32px;
    padding:4px 16px
}

.gdpr-translations-content-container {
    padding-top: 16px;
    border-top:1px solid #eee
}

.gdpr-footer {
    border-top: 1px solid #eee;
    margin: 32px 0 0;
    padding: 16px 0
}
